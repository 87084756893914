@keyframes headerAppear {
  0% {
    transform: translateY(-200%) scale(1);
    height: 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0) scale(1);
    height: auto;
    opacity: 1;
  }
}

@keyframes problemBoxG1 {
  0%,
  100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(31.5deg) translateX(-9px) translateY(5px);
  }
}

@keyframes problemBoxG3 {
  0%,
  100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-30.5deg) translateX(-3px) translateY(3px);
  }
}

@keyframes blink {
  0%,
  100% {
    filter: blur(var(--blur)) contrast(var(--contrast));
    opacity: 0;
    visibility: hidden;
  }

  50% {
    filter: blur(0) contrast(1);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fade-scroll {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes horizontal-scroll {
  0% {
    transform: translate3d(100%, 0%, 0);
  }

  50% {
    transform: none;
  }

  100% {
    transform: translate3d(-100%, 0%, 0);
  }
}

@keyframes backwards-scroll {
  0% {
    transform: translate3d(0%, -100%, 0);
  }

  50% {
    transform: none;
  }

  100% {
    transform: translate3d(0%, 100%, 0);
  }
}

@keyframes zoom-scroll {
  0% {
    filter: blur(5rem);
    transform: scale(0);
    opacity: 0;
    visibility: hidden;
  }

  50% {
    filter: blur(0);
    transform: none;
    opacity: 1;
    visibility: visible;
  }

  100% {
    filter: blur(3rem);
    transform: scale(1.5);
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes loadingShift {
  0%,
  100% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
}
