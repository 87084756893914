@import url("https://fonts.googleapis.com/css2?family=Anek+Bangla:wght@100..800&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Noto+Serif+Bengali:wght@100..900&display=swap");

// scss imports
@import "./keyframes";
@import "./functions";

// variables
$mobile-res: 670px;

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 4px;
    color: var(--red);
  }
  &::-webkit-scrollbar-thumb {
    border: 5px solid var(--red);
    border-radius: 4px;
    background: transparent;
  }
}

:root {
  --white: #fdfdfd;
  --white-100: #fffdfa;
  --white-200: #f7f2eb;

  --cream: #f1f1f1;
  --cream-translucent: #f1f1f15d;

  --black: #0d0a03;

  --primary-dark: #201932;
  --primary-dark-brand: var(--black);

  --gray: #9c9a9a;
  --gray-dark: #565656;

  --green: #14f094;
  --blue: #54efea;
  --orange: #ff930f;
  --pink: #ea37a3;
  --purple: #683ce4;
  --red: #ff2f49;

  --color-mixer: var(--white-200);

  --gradient-1: linear-gradient(90deg, #000000 13.78%, #ff0000 101.71%);

  --bg-white: var(--white-200);

  --ahj-container: 1440px;

  --ahj-header-height: 64px;

  --ahj-mobile-res: 670px;
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 4px;
    color: var(--red);
  }
  &::-webkit-scrollbar-thumb {
    border: 5px solid var(--red);
    border-radius: 4px;
    background: transparent;
  }
}

html {
  font-size: 16px;
  scroll-snap-type: y mandatory;
  timeline-scope: --section, --main, --hero;
  //   &::-webkit-scrollbar {
  //     display: none;
  //   }
  //   -ms-overflow-style: none;
  //   scrollbar-width: none;
}

body {
  background-color: var(--white, white);
  color: var(--black, black);

  //
  font-family: "Noto Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

main {
  // view-timeline: --main;
}

section {
  // padding-top: var(--ahj-header-height);
  // position: relative;

  // scroll-snap-align: start;
  // scroll-snap-stop: always;
  // view-timeline: --section;
  // height: 100dvh;
}

article {
  // margin-top: var(--ahj-header-height);
}

button,
[role="button"] {
  outline: none;
  border: none;
  cursor: pointer;
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

#hero {
  margin: 0 auto;
  position: relative;
  & > * {
    z-index: 1;
  }
  & > .timeline {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    view-timeline: --hero;
    z-index: 0;
  }
}

.ahj {
  &-font {
    &-anek-bangla {
      font-family: "Anek Bangla", sans-serif;
      font-optical-sizing: auto;
      font-style: normal;
      font-variation-settings: "wdth" 100;
    }
    &-noto-sans {
      font-family: "Noto Sans", sans-serif;
      font-optical-sizing: auto;
      font-style: normal;
      font-variation-settings: "wdth" 100;
    }
    &-inter {
      font-family: "Inter", sans-serif;
      font-optical-sizing: auto;
      font-style: normal;
    }
    &-lato {
      font-family: "Lato", sans-serif;
      font-style: normal;
    }
    &-noto-serif-bengali {
      font-family: "Noto Serif Bengali", serif;
      font-optical-sizing: auto;
      font-style: normal;
      font-variation-settings: "wdth" 100;
    }
  }
  &-bg {
    &-gradient {
      &-red-blue {
        background: linear-gradient(
          270deg,
          #14f094 2.85%,
          #683ce4 41.1%,
          #ea37a3 64.45%,
          #ff2f49 83.33%,
          #ff930f 101.71%
        );
      }
    }
    &-grid {
      // background-image: url("/media/bg-pattern.png");
      background-size: 30px 30px;
      background-repeat: repeat;
      background-position: -200px;
    }
  }
  &-card {
    &-standard {
      width: min(400px, 100%);
      display: flex;
      flex-direction: column;
      gap: 16px;
      text-decoration: none;
      color: var(--primary-dark);
      position: relative;
      &.bangla {
        line-height: 137%;
      }
      & > .image {
        width: 100%;
        aspect-ratio: 1200/628;
        overflow: hidden;
        & > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
        & > .custom {
          background-color: var(--cream);
          backdrop-filter: blur(30px);
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 1.4rem;
          padding: 24px;
        }
      }
      & > .title {
        display: flex;
        gap: 12px;
        flex-direction: column;
        &__line {
          height: 3px;
          background-color: var(--primary-dark);
          width: 90%;
          border-radius: 8px;
        }
      }
      & > .description {
        text-align: justify;
      }
      & > .link {
        color: var(--red);
      }
      & > a {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
    &-loading {
      &-standard {
        width: min(400px, 100%);
        display: flex;
        flex-direction: column;
        gap: 0px;
        text-decoration: none;
        color: var(--primary-dark);
        position: relative;
        & > .image {
          width: 100%;
          aspect-ratio: 1200/628;
          overflow: hidden;
          background: linear-gradient(
            60deg,
            color-mix(in srgb, var(--gray) 50%, var(--color-mixer)) 0%,
            var(--gray) 50%,
            color-mix(in srgb, var(--gray) 50%, var(--color-mixer)) 100%
          );
          // background-color: color-mix(in srgb, color percentage, color percentage);
          background-size: 200%;
          animation: loadingShift 2s ease-in-out infinite;
        }
        & > * {
          & > span {
            display: flex;
            font-size: inherit;
            height: 1lh;
            margin: 4px 0;
            background: linear-gradient(
              60deg,
              color-mix(in srgb, var(--gray) 50%, var(--color-mixer)) 0%,
              var(--gray) 50%,
              color-mix(in srgb, var(--gray) 50%, var(--color-mixer)) 100%
            );
            // background-color: color-mix(in srgb, color percentage, color percentage);
            background-size: 200%;
            animation: loadingShift 2s ease-in-out infinite;
          }
        }
        & > .title {
          & > span {
            width: 90%;
          }
        }
        & > .description {
          & > span {
            width: 75%;
            &:nth-child(2) {
              width: 70%;
            }
            &:nth-child(3) {
              width: 60%;
            }
          }
        }
        & > .link {
          & > span {
            width: 50%;
          }
        }
      }
    }
    &-series_article_add {
      width: min(400px, 100%);
      display: flex;
      flex-direction: column;
      text-decoration: none;
      color: var(--primary-dark);
      position: relative;
      & > .banner {
        display: flex;
        padding: 24px;
        background-color: var(--gray);
        border-radius: 12px 12px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3rem;
        border: 1px solid var(--gray);
      }
      & > .content {
        border-radius: 0 0 12px 12px;
        border: 1px solid var(--gray);
        padding: 24px;
      }
    }
  }
  &-header {
    &-line {
      background-image: var(--gradient-1);
      height: 2px;
    }
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 999;
    overflow: hidden;
    background-color: var(--white);
    // animation: headerAppear ease-in-out both;
    // animation-timeline: --hero;
    // transform-origin: top center;
    &-container {
      &-content {
        position: relative;
        width: min(var(--ahj-container), 100%);
        margin: 0 auto;
        padding: 16px 24px 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
        & > .logo {
          font-size: 1.8rem;
          font-weight: 600;
          line-height: 100%;
          color: var(--black);
          text-decoration: none;
          & > span {
            color: var(--red);
          }
        }
        & > .right {
          display: flex;
          flex-wrap: wrap-reverse;
          align-items: center;
          gap: 16px;
          line-height: 100%;
          & > a {
            color: var(--red);
            text-decoration: underline;
          }
          & > .ahj-join {
            text-decoration: none;
            background-color: var(--red);
            color: var(--white);
            font-weight: 600;
            padding: 8px 24px;
            border-radius: 24px;
          }
          & > .profile-icon {
            width: 30px;
            aspect-ratio: 1/1;
            border-radius: 50%;
            overflow: hidden;
            & > img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
            & > span {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: var(--red);
              color: var(--black);
              font-size: 1.5rem;
              font-weight: 800;
            }
          }
        }
      }
    }
  }
  &-container {
    overflow: hidden;
    position: fixed;
    inset: 0;
    padding-top: var(--ahj-header-height);
    animation: zoom-scroll ease-in-out both;
    animation-timeline: --section;
    margin: 0 auto;
    & > .credit {
      position: absolute;
      top: 20px;
      left: 20px;
      mix-blend-mode: color-burn;
    }
  }

  // page specific
  &-home {
    width: min(1440px, 100%);
    margin: 0 auto;
    padding: 24px;
    & > .title {
      font-size: 2.4rem;
      &:not(:first-child) {
        padding-top: 32px;
      }
    }
    &-body {
      padding-top: 24px;
      display: grid;
      gap: 24px;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-flow: dense;
      @media (max-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 670px) {
        grid-template-columns: 1fr;
      }
    }
  }
  &-dashboard {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: calc(100dvh - var(--ahj-header-height));
    & > button {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 12px 24px;
      background-color: transparent;
      background-color: var(--red);
      color: var(--black);
      font-weight: 600;
      font-size: 1.1rem;
    }

    & > .container {
      padding-top: 24px;
      display: grid;
      gap: 24px;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-flow: dense;
      @media (max-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 670px) {
        grid-template-columns: 1fr;
      }
      & > .card {
        width: min(400px, 100%);
        display: flex;
        flex-direction: column;
        text-decoration: none;
        color: var(--primary-dark);
        position: relative;
        & > .banner {
          display: flex;
          padding: 24px;
          background-color: var(--gray);
          border-radius: 12px 12px 0 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 3rem;
          border: 1px solid var(--gray);
        }
        & > .content {
          border-radius: 0 0 12px 12px;
          border: 1px solid var(--gray);
          padding: 24px;
        }
      }
    }
  }
  &-series_view {
    & > * {
      background-color: var(--white-100);
    }
    & > .background {
      width: 100%;
      aspect-ratio: 791/413;
      position: relative;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-attachment: fixed;
      position: sticky;
      top: var(--ahj-header-height);
      left: 0;
      right: 0;
      z-index: -1;
      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    & > .details {
      padding: 32px 24px;
      font-size: 1rem;
      font-weight: 500;
      text-align: justify;
    }
    & > .content {
      padding: 32px 24px;
      display: grid;
      gap: 24px;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-flow: dense;
      @media (max-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 670px) {
        grid-template-columns: 1fr;
      }
    }
    & > .share-button {
      position: fixed;
      bottom: 16px;
      right: 16px;
      display: flex;
      background-color: var(--red);
      color: var(--white-200);
      border-radius: 50%;
      padding: 16px;
      font-size: 2rem;
    }
  }
  &-sign {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    height: calc(100dvh - var(--ahj-header-height));
    @media (max-width: $mobile-res) {
      display: flex;
      flex-direction: column-reverse;
    }
    & > .content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 24px;
      padding: 24px;
      @media (max-width: $mobile-res) {
        flex: 1;
      }
      & > h1 {
        font-size: 1.5rem;
        color: var(--primary-dark);
        text-align: center;
        width: min(500px, 100%);
      }
      & > button {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 12px 24px;
        background-color: transparent;
        background-color: color-mix(in srgb, var(--black) 100%, #ffffff00);
        color: var(--white);
        font-weight: 600;
        & > span {
          height: 1.5rem;
          width: auto;
          & > img {
            height: 1.5rem;
            width: auto;
          }
        }
      }
    }
    & > .graphics {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--red);
      @media (max-width: $mobile-res) {
        aspect-ratio: 4/3;
      }
      & > h2 {
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 100%;
        color: var(--white);
        text-decoration: none;
        & > span {
          color: var(--black);
        }
      }
    }
  }
  &-manual-pusher {
    & > form {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 24px;
      & > * {
        padding: 8px 16px;
        font-size: 1rem;
        border-radius: 16px;
      }
    }
  }
  &-loading {
    position: absolute;
    inset: 0;
    height: 100dvh;
    width: 100dvw;
    background-color: color-mix(in srgb, var(--white) 40%, #ffffff00);
    // backdrop-filter: blur(20px);
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-blogWrite-container {
    display: flex;
    height: calc(100dvh - var(--ahj-header-height));
    width: 100%;
    & > main {
      flex: 3;
      height: 100%;
      overflow: auto;
      & > form {
        width: min-content(800px, 100%);
        margin: auto;
        padding: 56px 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        & > .background {
          background-color: var(--cream);
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          & > input {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
          }
        }
        & > p {
          font-size: 1rem;
        }
        & > .element {
          display: flex;
          flex-direction: column;
          gap: 8px;
          & > input {
            font-family: "Noto Sans", sans-serif;
          }
          & > select {
            padding: 8px 12px;
          }
          &.type {
            & > input {
              font-family: "Noto Sans", sans-serif;
              font-size: 1rem;
              font-weight: 500;
              resize: none;
              border: 1px solid var(--black);
              border-radius: 4px;
              outline: none;
              background-color: transparent;
              padding: 4px 8px;
            }
          }
          &.title {
            & > input {
              font-size: 2.5rem;
              font-family: "Noto Sans", sans-serif;
              border: none;
              outline: none;
              background-color: transparent;
            }
          }
          &.body {
            & > textarea {
              font-family: "Noto Sans", sans-serif;
              font-size: 1rem;
              font-weight: 500;
              resize: none;
              border: none;
              outline: none;
              field-sizing: content;
              background-color: transparent;
            }
            &.h2 {
              & > textarea {
                font-size: 2rem;
                margin: 8px 0;
                font-weight: 600;
              }
            }
            &.h3 {
              & > textarea {
                font-size: 1.55rem;
                margin: 8px 0;
                font-weight: 600;
              }
            }
            &.h4 {
              & > textarea {
                font-size: 1.25rem;
                margin: 4px 0;
                font-weight: 600;
              }
            }
            &.h5 {
              & > textarea {
                font-size: 1.125rem;
                margin: 4px 0;
                font-weight: 600;
              }
            }
            &.h6 {
              & > textarea {
                font-size: 1.025rem;
                margin: 4px 0;
                font-weight: 600;
              }
            }
          }
          &.ul {
            position: relative;
            & > ul {
              padding-left: 24px;
              list-style: outside;
              & > li {
                font-size: 1rem;
                font-weight: 500;
              }
            }
            & > textarea {
              position: absolute;
              inset: 0;
              opacity: 0.5;
              padding-left: 24px;
              min-height: 1lh;
            }
          }
          &.quote {
            position: relative;
            & > blockquote {
              font-size: 0.93rem;
              font-weight: 600;
              font-style: italic;
              min-height: 1lh;
            }
            & > textarea {
              font-size: 0.93rem;
              font-weight: 600;
              font-style: italic;
              // position: absolute;
              // inset: 0;
              // opacity: 0.6;
              // min-height: 1lh;
              // padding-left: 6px;
            }
          }
          &.a {
            & > textarea {
              font-size: 0.9rem;
              font-weight: 400;
              color: var(--red);
              text-decoration: underline;
            }
          }
        }
        & > button {
          &.add {
            padding: 8px 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            font-size: 1.125rem;
            font-weight: 500;
            background-color: transparent;
            border: 1.3px solid var(--black);
          }
          &.submit {
            padding: 8px 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            font-size: 1.125rem;
            font-weight: 500;
            color: var(--cream);
            background-color: var(--black);
            border: 1.3px solid var(--black);
          }
        }
        & > .error {
          color: var(--orange);
          & > ul {
            list-style: inside;
            & > li {
              font-size: 1rem;
              font-weight: 500;
            }
          }
        }
      }
    }
    & > aside {
      flex: 1;
      border-left: 1px solid var(--black);
      padding: 32px 24px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      & > .element {
        display: flex;
        flex-direction: column;
        gap: 8px;
        & > input {
          width: 100%;
          padding: 5px 16px;
          border-radius: 4px;
          border: 1px solid var(--gray);
          outline: 0;
        }
        & > select {
          padding: 8px 12px;
        }
        & > button {
          padding: 8px 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          font-size: 1.125rem;
          font-weight: 500;
          background-color: transparent;
          border: 1.3px solid var(--black);
        }
      }
    }
  }

  // long content
  &-long-content {
    padding-bottom: 56px;
    & > .background {
      width: min(800px, 100%);
      margin: 0 auto;
      aspect-ratio: 1200/628;
      // background-image: url("/media/bgs/july-18.webp");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      @media (max-width: $mobile-res) {
        // aspect-ratio: 16/10;
      }
      & > img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: contain;
      }
      & > .image-credit {
        font-size: 0.7rem;
        color: gray;
      }
    }
    & > .content {
      width: min(800px, 100%);
      margin: 0 auto;
      background-color: var(--white);
      padding: 24px;
      color: var(--primary-dark);
      & > * {
        text-align: justify;
      }

      @media (max-width: 800px) {
        width: calc(100%);
      }
      & > .chips {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
        margin: 0;
        & > .chip {
          color: var(--red);
          background-color: color-mix(in srgb, var(--red) 20%, #ffffff00);
          line-height: 100%;
          padding: 4px 12px;
          border-radius: 12px;
          text-decoration: dashed;
        }
      }
      & > h1 {
        font-size: 2.5rem;
        margin: 16px 0;
        text-align: left;
        font-weight: 600;
      }
      & > h2 {
        font-size: 2rem;
        margin: 16px 0;
        font-weight: 600;
      }
      & > h3 {
        font-size: 1.55rem;
        margin: 16px 0;
        font-weight: 600;
      }
      & > h4 {
        font-size: 1.25rem;
        margin: 8px 0;
        font-weight: 600;
      }
      & > h5 {
        font-size: 1.125rem;
        margin: 8px 0;
        font-weight: 600;
      }
      & > h6 {
        font-size: 1.025rem;
        margin: 8px 0;
        font-weight: 600;
      }
      & > p {
        font-size: 1rem;
        font-weight: 500;
        margin: 16px 0;
      }
      & > a,
      & > .refs > a {
        font-size: 0.9rem;
        font-weight: 400;
        color: var(--red);
        text-decoration: underline;
      }
      & > blockquote {
        font-size: 0.93rem;
        font-weight: 600;
        font-style: italic;
      }
      & > ul {
        list-style: inside;
        & > li {
          font-size: 1rem;
          font-weight: 500;
        }
      }
      & > .ref {
        font-size: 1.125rem;
        font-style: italic;
        font-weight: 600;
        text-decoration: underline;
      }
      & > .refs {
        display: flex;
        flex-direction: column;
      }
      & > .count-overview {
        display: flex;
        flex-wrap: wrap;
        & > .item {
          flex: 1;
          text-align: center;
          color: var(--primary-dark);
          border: 1px dashed var(--primary-dark);
          padding: 6px;
          @media (max-width: 600px) {
            width: 50%;
            flex: auto;
          }
          & > h3 {
            font-size: 1.6rem;
          }
        }
      }
    }
    & > .share-button {
      position: fixed;
      bottom: 16px;
      right: 16px;
      display: flex;
      background-color: var(--red);
      color: var(--white-200);
      border-radius: 50%;
      padding: 16px;
      font-size: 2rem;
    }
  }

  //   not found page
  &-page {
    &-notfound {
      min-height: 100dvh;
      background-color: var(--bg-white);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 24px;
      gap: 16px;
      & > h1 {
        font-size: 2rem;
      }
      & > a {
        color: var(--red);
        text-decoration: underline;
        font-weight: 600;
        text-decoration: none;
      }
    }
  }
}
